<template>
    <v-container class="container" fluid :style="bindStyle">
        <sub-header-component
            :propUser="user"
            propImage="icon_map_3x"
            :propSubtitle="floor.floorName"
            :propIsFloor="true"
            :propRotation="rotation"
        />
        <div class="floor_grid">

            <v-row
                v-for="i in floor.gridRowCount()"
                :key="i"
                align="center"
                justify="center"
                no-gutters
            >
                <div class="first_col" :style="bindStyleCell(i,1)"></div>
                <div class="last_col" :style="bindStyleCell(i,1)"></div>

                <v-col
                    v-for="j in floor.gridColCount()"
                    class="floor-col"
                    :key="j"
                    :style="bindStyleCell(i, j)"
                >
                    <floor-detail-component
                        :propUser="user"
                        :propSummary="summary"
                        :propArea="propArea"
                        :propFloor="floor"
                        :propRotation="rotation"
                        :propDetail="floorDetail.getDetail(i, j)"
                    />
                    <div
                        v-if="floor.getTitle(i, j)"
                        class="cell-title"
                        :style="bindStyleCell(i, j)"
                    >
                        <span>{{ floor.getTitle(i, j) }}</span>
                    </div>
                    <div
                        v-if="floor.findCell(i, j).image"
                        class="cell-image"
                        :style="bindStyleCell(i, j)"
                    >
                        <img :src="src(floor.findCell(i, j))" />
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="footer">
            <div>{{ header.dispTimestamp() }} 更新</div>
            <div v-if="header.dispTimestamp()"> 更新</div>
        </div>
    </v-container>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";
import SubHeaderComponent from "../components/SubHeaderComponent";
import FloorDetailComponent from "../components/FloorDetailComponent";
import HeaderComponent from "../components/HeaderComponent";
import constant from "../store/constant";
import models from "../store/models";

const vm = {
    mixins: [mixinApp, mixinDetail],
    components: {
        SubHeaderComponent,
        FloorDetailComponent,
        HeaderComponent
    },
    props: {
        propFloorDetail: null,
        propRotation: null,
    },
    computed: {
        bindStyle() {
            return {
                "--container-width": "100vw",
            };
        },
        floorDetail() {
            return this.propFloorDetail;
        },
        floor() {
            return this.propFloorDetail.floor;
        },
        header() {
            return models.createHeader(
                "footer",
                "footer",
                this.summary?.getLatestTimestamp
            );
        },
        rotation() {
            return this.propRotation;
        },
    },
    methods: {
        createSearchCondition() {
            const ret = models.createSearchCondition();

            ret.searchUnit = constant.searchUnitFloor();
            ret.userKind = this.user.userKind;
            ret.hierarchyCode = this.hierarchy.hierarchyCode;

            return ret;
        },
        getBorderColor(row, col, show) {
            return show(row, col) ? "#666666" : this.getMainBackgroundColor();
        },
        getBorder(row, col, show) {
            return "1px solid" + this.getBorderColor(row, col, show);
        },
        getCellTitleFontSize(row, col) {
            const cell = this.floor.findCell(row, col);

            return cell.fontSize
                ? cell.fontSize
                : constant.getFontSize(
                      cell.title,
                      this.floor.cellWidth(),
                      constant.floorMaxFontSize()
                  ) + "vw";
        },
        getImageSize() {
            const h = this.floor.cellHeight();
            const w = this.floor.cellWidth();
            const adjust = 2;

            return h < w ? h - adjust + "vh" : w - adjust + "vw";
        },
        src(cell) {
            return constant.src(cell.image);
        },
        bindStyleCell(row, col) {
            return {
                "--cell-height":
                    this.floor.cellHeight() * this.innerHeightCriteria + "px",
                "--cell-width": this.floor.cellWidth() + "vw",
                "--cell-border-top": this.getBorder(row, col, this.floor.showTop),
                "--cell-border-bottom": this.getBorder(row, col, this.floor.showBottom),
                "--cell-border-left": this.getBorder(row, col, this.floor.showLeft),
                "--cell-border-right": this.getBorder(row, col, this.floor.showRight),
                "--cell-title-font-size": this.getCellTitleFontSize(row, col),
                "--cell-title-align": this.floor.findCell(row, col).getAlign(),
                "--cell-title-width": this.floor.findCell(row, col).getWidth() + "%",
                "--cell-image-size": this.getImageSize(),
            };
        }
    },
    created() {
    },
};
export default vm;
</script>

<style scoped>
.container {
    width: var(--container-width);
}

.cell-title,
.cell-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.cell-title {
    white-space: nowrap;
    transform: var(--card-span-transform);
    font-size: var(--cell-title-font-size);
}

.cell-title span {
    text-align: var(--cell-title-align);
    width: var(--cell-title-width);
    padding-left: 1vw;
    padding-right: 1vw;
}

.cell-image img {
    height: var(--cell-image-size);
    width: var(--cell-image-size);
}

.footer {
    color: white;
    text-align: right;
}
</style>
