<template>
    <v-main class="main">
        <loading-component :propLoading="loading" />
        <header-component :propHeader="header" />
        <v-carousel
            hide-delimiter-background
            :height="carouselHeightPx"
            :cycle="hierarchy.useCycle()"
            :interval="hierarchy.getInterval()"
            v-model="currentIndex"
            :key="carouselKey"
        >
            <v-carousel-item
                v-for="(floorDetail, i) in floorDetailList"
                :reverse-transition="hierarchy.useTransition()"
                :key="i"
            >
                <floor-component
                    v-if="!(floorDetail.dispImage === 0)"
                    :propUser="user"
                    :propSummary="summary"
                    :propArea="propArea"
                    :propFloorDetail="floorDetail"
                    :propRotation="rotation"
                />
                <div v-if="floorDetail.dispImage === 0" class="display-image">
                    <img
                        :style="bindStyle"
                        src="../assets/display-text-measure.png"
                    />
                </div>
            </v-carousel-item>
        </v-carousel>
        <fab-component
            :propUser="user"
            :propArea="propArea"
            :propFabPrimary="fab"
            :propFabRotation="fabRotation"
            :propDownloadable="true"
            :propGetLoading="getLoading"
        />
    </v-main>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinSensor from "../store/mixin.sensor";
import mixinDetail from "../store/mixin.detail";
import LoadingComponent from "../components/LoadingComponent";
import HeaderComponent from "../components/HeaderComponent";
import FabComponent from "../components/FabComponent";
import FloorComponent from "../components/FloorComponent";
import models from "../store/models";
import constant from "../store/constant";

const vm = {
    mixins: [mixinApp, mixinSensor, mixinDetail],
    components: {
        LoadingComponent,
        HeaderComponent,
        FabComponent,
        FloorComponent,
    },
    props: {
        propRotationSelectNo: 0,
    },    
    data() {
        return {
            currentIndex: 0,
            carouselKey: false,
        };
    },
    computed: {
        bindStyle() {
            return {
                "--display-image-size": this.innerWidth * 0.75 + "px",
            };
        },
        hierarchyName() {
            return this.hierarchy.hierarchyName;
        },
        header() {
            return models.createHeader(
                this.hierarchyName,
                "icon-loca",
                this.summary?.getLatestTimestamp
            );
        },
        rotation() {
            const ret = {};
            ret.func = this.rotationStatus;
            ret.no = this.selectNo;
            return ret;
        },
        fabRotation() {
            return models.createFab("icon-rotation", this.fabRotationStatus);
        },
        fab() {
            return models.createFab("icon-fixed", this.transitionRanking);
        },
        floorDetailList() {
            if (!this.user || !this.summary) {
                return [];
            }

            const list = this.hierarchy.getFloorDetailList(
                this.displayableDetailList
            );
            
            if(this.user.isUser() && !(this.user.getAddPage().toUpperCase() === "OFF")){
                list.push({ dispImage: 0 });
                return list;
            }
            
            return list;
        },
        selectedIndex() {
            if (!this.currentSensor) {
                return 0;
            }
            const ret = this.floorDetailList.findIndex((val) =>
                val.match(this.currentSensor)
            );

            return ret > 0 ? ret : 0;
        }
    },
    methods: {
        createSearchCondition() {
            const ret = models.createSearchCondition();

            ret.searchUnit = constant.searchUnitFloor();
            ret.userKind = this.user.userKind;
            ret.hierarchyCode = this.hierarchy.hierarchyCode;

            return ret;
        },
        transitionRanking() {
            this.$router.push({
                name: "ranking",
                params: {
                    propUser: this.user,
                    propSummary: this.summary,
                    propArea: this.propArea,
                    propSearchUnit: constant.searchUnitFloor(),
                    propRotationSelectNo: this.rotation.no,

                },
                query: this.getQuery(),
            });
        },
        afterResize() {
            this.carouselKey = !this.carouselKey;
        },
        rotationStatus(no) {
            this.changeStatus(no);
        },
        fabRotationStatus() {
            this.changeStatus("");
        }
    },
    created() {
        this.autoUpdating();
        //propRotationSelectNo がない場合はデフォルト
        if (this.propRotationSelectNo != null){
            this.selectNo = this.propRotationSelectNo;
        }
              
        this.currentIndex = this.selectedIndex;
    },

};

export default vm;
</script>

<style scoped>
@import '../assets/css/floor.min.css';
.main {
    position: relative;
}

.display-image {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
}

.display-image img {
    width: var(--display-image-size);
    height: auto;
}
</style>
