<template>
    <div class="main floor_wrapper" v-if="detail" :style="bindStyle">
        <div
            class="floor_sensor"
            :class="{[this.getSensorClass()]: true}"
            @click="
                transitionSensor(
                    user,
                    summary,
                    detail.sensor,
                    'floor',
                    true,
                    propArea,
                    propRotation.no
                )
            "
            :style="bindStyle"
        >
            <div class="floor_sensorName" ref="location">
                <span >{{ sensor.location }}</span>
                <div class="ring-sensor">
                    <img
                        v-if="ringSensorValid"
                        class="icon-ring-sensor"
                        src="../assets/icon-ring-sensor-on.png"
                        :style="bindStyle"
                    />
                    <img
                        v-else-if="doNotDisturbSensorValid"
                        class="icon-ring-sensor"
                        src="../assets/icon-ring-sensor-off.png"
                        :style="bindStyle"
                    />
                </div>
            </div>

            <div class="floor_sensor_contents">

                <div class="floor_sensorContents_Main">

                    <div class="floor_sub sub1">
                        <div class="icon_area">
                            <img
                                :class="this.setSubs(rotation.no, 1).mode.class"
                                :src="this.setSubs(rotation.no, 1).mode.image"
                                :style="bindStyle"
                            />
                        </div>
                        <div class="disp_area">
                            <span class="disp" :class="this.setSubs(rotation.no, 1).mode.class" v-html="this.setSubs(rotation.no, 1).disp"></span>
                        </div>
                    </div>
                </div>

                <div v-if="sensorSub2DispFlg()">
                    <div class="floor_sensorContents_Sub">
                        <div class="floor_sub sub2">
                            <div class="icon_area">
                                <img
                                    :class="this.setSubs(rotation.no, 2).mode.class"
                                    :src="this.setSubs(rotation.no, 2).mode.image"
                                    :style="bindStyle"
                                />
                            </div>
                            <div class="disp_area">
                                <span class="disp" :class="this.setSubs(rotation.no, 2).mode.class" v-html="this.setSubs(rotation.no, 2).disp"></span>
                            </div>
                            <div class="icon_area">
                                <img
                                    :class="this.setSubs(rotation.no, 3).mode.class"
                                    :src="this.setSubs(rotation.no, 3).mode.image"
                                    :style="bindStyle"
                                />
                            </div>
                            <div class="disp_area">
                                <span class="disp" :class="this.setSubs(rotation.no, 3).mode.class" v-html="this.setSubs(rotation.no, 3).disp"></span>
                            </div>
                        </div>

                    </div>
                    
                </div>

            </div>

            <div class="floor_Face">

                <img
                    class="icon face-image"
                    v-if="isDispFace()"
                    :src="status.infos.src_black"
                    :style="bindStyle"
                />
            </div>

        </div>

    </div>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";
import mixinSensor from "../store/mixin.sensor";

// const Adjust = 0.85;
// const MarginHeight = 2;
const FaceHeight = 25;
const RingSensorHeight = 25;
const FaceAdjust = 5;
// const LocationHeight = 55;
// const Co2cHeight = 25;
const IconHeight = 15;
// const TempHumiHeight = IconHeight;
const vm = {
    mixins: [mixinApp, mixinDetail, mixinSensor],
    props: {
        propFloor: null,
        propRotation: null
    },
    data() {
        return {
            floor: this.propFloor,
            locationWidth: 0,
            locationHeight: 0
        };
    },
    computed: {
        bindStyle() {
            
            return this.strategy.bindStyle(
                {
                    // "--main-margin-top": this.getCardMarginTop() + "px",
                    // "--main-margin-left": this.getCardMarginLeft() + "vw",
                    // "--card-height": this.getCardHeight() + "px",
                    // "--card-width": this.getCardWidth() + "vw",
                    "--card-font-color": "black",
                    "--card-border-color": "black",
                    "--ring-sensor-height": this.getRingSensorHeight() + "px",
                    // "--face-height": FaceHeight + "px",
                    // "--face-margin-top": FaceAdjust + "px",
                    // "--content-height": this.getContentHeight() + "px",
                    // "--icon-height": IconHeight + "px",
                    // "--location-height": LocationHeight + "px",
                    // "--location-width": this.getCardWidth() - 2 + "vw",
                    // "--location-font": this.getLocationFontSize() + "px",
                    // "--co2c-height": Co2cHeight + "px",
                    // "--text-margin-bottom": MarginHeight + "px",
                    // "--text-margin-bottom": MarginHeight + "px",
                    // "--card-border-width": 3 + "px",
                    "--sensor-sensorname-size": this.sensorNameSize()+ "px",
                    "--sensor-sub1-size": this.sensorSub1Size()+ "px",
                    "--sensor-sub2-size": this.sensorSub2Size()+ "px",
                    "--sensor-row" : this.sensor.sizeRow,
                    "--sensor-row-px" : this.sensor.sizeRow + "px",
                    "--sensor-col" : this.sensor.sizeCol,
                    "--sensor-width" : this.sensorWidth + "px",
                    "--cell-height": this.floor.cellHeight() * this.innerHeightCriteria + "px",
                    "--cell-width": this.floor.cellWidth() + "vw",
                    "--status-code": this.status.infos.code,
                },
                this.detail,
                true
            );
        },
        sensorHeight() {
            return this.floor.cellHeight() * this.innerHeightCriteria * this.sensor.sizeRow;
        },
        sensorDetailHeight() {
            return this.floor.cellHeight() * this.innerHeightCriteria * (this.sensor.sizeRow - 1);
        },  
        sensorWidth() {
            return this.floor.cellWidth() * this.innerWidthCriteria * this.sensor.sizeCol;
        },
        ringSensorValid() {
            return this.isEnableRing && this.isRingSensorAtTime(this.sensor.sensorNumber);
        },
        doNotDisturbSensorValid() {
            return this.isEnableRing && this.isDoNotDisturbSensor(this.sensor.sensorNumber);
        },
        rotation() {
            return this.propRotation;
        },
        status() {
            return this.getStatus(this.propRotation.no, this.user.isUser());
        }
    },
    methods: {
        // センサー名のサイズ調整
        sensorNameSize(){
            const cellHeight = this.floor.cellHeight() * this.innerHeightCriteria;
            const marginWidth = 0.85;
            const marginHeight = 0.7;
            const fontSizeByHeight = cellHeight * marginHeight;
            const len = this.sensor.location.length + 1 || 1;
            const sensorWidth = this.locationWidth != null && this.sensorWidth > this.locationWidth ? this.locationWidth : this.sensorWidth;
            const sensorMarginedWidth = sensorWidth * marginWidth;
            
            //文字サイズを高さに併せて設定し、はみ出るようなら少しずつ小さくしていく
            let fontSize = fontSizeByHeight
            for (
                fontSize;
                fontSize * len > sensorMarginedWidth && fontSize > 2;
                fontSize
                )
            {
                fontSize = fontSize > 8 ? fontSize - 4 : fontSize - 1;
            }
            return fontSize;
        }, 
        // センサー詳細メイン項目のサイズ調整
        sensorSub1Size(){
            const sizeCol = this.sensor.sizeCol < 4 ? this.sensor.sizeCol : 4;
            const sensorWidth = this.floor.cellWidth() * this.innerWidthCriteria * sizeCol;
            const sizeRow = this.sensor.sizeRow < 7 ? this.sensor.sizeRow : 7;
            const sensorHeight = this.floor.cellHeight() * this.innerHeightCriteria * sizeRow;
            if( sensorWidth >= sensorHeight)
            {
                if (sizeCol <= 3) {
                    return  sensorHeight / 4.5;
                }
                return  sensorHeight / 4;
            }
           return sensorWidth / 5;
        }, 
        // センサー詳細サブ項目のサイズ調整
        sensorSub2Size(){
            //Sub1の55%の大きさにする
            return this.sensorSub1Size() * 0.55;
        }, 
        // サブ項目を表示しきれる高さが確保できない場合は表示を消す
        sensorSub2DispFlg(){
            // 高さ40pxを下回ると非表示にする
            return this.sensorDetailHeight > 40;
        },
        /**
         * 0 > co2c
         * 1 > temp
         * 2 > humi
         */
        setSubs(no, key) {
            var priority = this.getParamType(no).priority;
            var arr = this.getParamType(priority[key-1]);
            return arr;
        },
        getFan() {
            var fan = 150;
            if (this.sensor.sizeCol > 8 && this.sensor.sizeRow > 8) {
                fan = 300;
            }
            return fan;
        },
        getSensorClass() {
            //どれにも該当しない場合は「m」
            var sensorClass = "size_m_sensor";
            //3*3以下
            if (
                this.sensor.sizeCol <= 3 ||
                this.sensor.sizeRow <= 3
            ) {
                sensorClass = "size_xs_sensor";
            //4*4 ~ 5*5以下
            } else if (
                this.sensor.sizeCol <= 5 ||
                this.sensor.sizeRow <= 5
            ) {
                sensorClass = "size_s_sensor";
            //6*6 ~ 7*7以下
            } else if (
                this.sensor.sizeCol <= 7 ||
                this.sensor.sizeRow <= 7
            ) {
                sensorClass = "size_m_sensor";
            //8*8以上
            } else if (
                (this.sensor.sizeCol >= 8) &&
                (this.sensor.sizeRow >= 8)
            ) {
                sensorClass = "size_l_sensor";
            }
            return sensorClass;
        },
        getRingSensorHeight() {
            return this.isIphone || this.isIphoneByWidth ? IconHeight : RingSensorHeight;
        },
        // getCardMarginTop() {
        //     return (this.cellHeight - this.getCardHeight()) / 2;
        // },
        // getCardMarginLeft() {
        //     return (this.cellWidth - this.getCardWidth()) / 3;
        // },        
        // getCardHeight() {
        //     return this.cellHeight * Adjust;
        // },
        // getCardWidth() {
        //     return this.cellWidth * Adjust;
        // },
        // getContentHeight() {
        //     return this.getCardHeight() - this.faceHeigtMargin() - 20;
        // },
        faceHeigtMargin() {
            return FaceHeight + FaceAdjust * 2;
        },
        // locationHeightMargin() {
        //     return LocationHeight + MarginHeight * 2;
        // },
        // co2cHeightMargin() {
        //     return Co2cHeight + MarginHeight * 2;
        // },
        // tempHumiHeightMargin() {
        //     return TempHumiHeight + MarginHeight * 2;
        // },
        isDispFace() {
            return this.status.infos.src_black != null ? true : false;
            //return this.detail.isEmpty() ? false : true;
            // return true;
            // return (
            //     !this.detail.isEmpty() &&
            //     this.getCardHeight() > this.faceHeigtMargin() + 40
            // );
        },
        isDispLocation() {
            return true;
            // return (
            //     this.getCardHeight() >
            //     this.faceHeigtMargin() + this.locationHeightMargin()
            // );
        },
        isDispCo2c() {
            return (
                this.getCardHeight() >
                this.faceHeigtMargin() +
                    this.locationHeightMargin() +
                    this.co2cHeightMargin() +
                    this.tempHumiHeightMargin()
            );
        },
        isDispTempAndHumi() {
            return (
                this.getCardHeight() >
                this.faceHeigtMargin() +
                    this.locationHeightMargin() +
                    this.tempHumiHeightMargin()
            );
        },
        afterResize() {
            //this.$refs.locationにアクセスできない場合があるため、null checkも実施
            if (this.detail && this.$refs && this.$refs.location) {
                // タイトル幅を算出する際に利用
                this.locationWidth = this.$refs.location.clientWidth;
                this.locationHeight = this.$refs.location.clientHeight;
            }
        },
    },
    mounted() {
        this.$nextTick(function () {
            // ビュー全体がレンダリングされた後にのみ実行
            // タイトル幅を算出する際に利用
            this.afterResize();
        });
        
    },
};

export default vm;
</script>

<style scoped>
.main {
    vertical-align: middle;
    margin-top: var(--main-margin-top);
    margin-left: var(--main-margin-left);
    color: black;
}

.card {
    position: absolute;
    cursor: var(--cursor);
    pointer-events: var(--pointer-events);
    text-align: center;
    height: var(--card-height);
    color: var(--card-font-color);
    border-color: var(--card-border-color);
    border-style: var(--card-border-style);
    border-width: var(--card-border-width);
    border-radius: var(--card-border-radius);
    background-color: var(--ventilation-level-color);
}

.card span {
    transform: var(--card-span-transform);
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--content-height);
    width: var(--card-width);
}

.face {
    margin-top: var(--face-margin-top);
    margin-bottom: var(--face-margin-top);
}

.face,
.face-image {
    height: var(--face-height);
}

.co2c {
    text-align: center;
    margin-top: var(--text-margin-top);
    margin-bottom: var(--text-margin-bottom);
}

.location {
    height: var(--location-height);
    width: var(--location-width);
    font-size: var(--location-font);
    background-color: var(--main-background-color);
    border-color: var(--card-border-color);
    border-style: var(--card-border-style);
    border-width: var(--card-border-width);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5vh;
}

.location span {
    color: white;
}

.co2c {
    height: var(--co2c-height);
}

.temp-humi {
    margin-top: var(--text-margin-top);
    margin-bottom: var(--text-margin-bottom);
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--card-width);
}

.box span {
    font-size: smaller;
}

.icon {
    margin: 1%;
    height: var(--icon-height);
}

.icon-temp {
    margin-left: 0vw;
}

.icon-humi {
    margin-left: 1vw;
}
</style>
